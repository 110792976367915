<template>
  <div>
    <v-card class="card-shadow border-radius-xl pb-4">
      <v-toolbar elevation="0">
        <v-btn class="bg-light" elevation="0" @click="$emit('close', true)">
          <v-icon size="15" class="">fas fa-times</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <div class="font-weight-bolder text-xl">Dokumente</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          @click="addDucumentModal = true"
          :elevation="0"
          color="#cb0c9f"
          class="font-weight-bolder btn-dark bg-light py-4 px-4 mx-2"
        >
          <v-icon size="15" class="">fas fa-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <div v-if="selectedProject.documents.length > 0">
        <Document
          v-for="(document, index) in selectedProject.documents"
          :key="index"
          :document="document"
        />
      </div>

      <div class="pa-5" v-else>
        <div>Du hast noch keine Dokumente hochgeladen, jetzt hochladen?</div>

        <v-btn
          @click="addDucumentModal = true"
          :elevation="0"
          color="#cb0c9f"
          class="font-weight-bolder btn-dark bg-light px-4 mt-5"
        >
          Dokument hinzufügen
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="addDucumentModal" max-width="500px" persistent>
      <AddDocument @close="addDucumentModal = false" />
    </v-dialog>
  </div>
</template>
    
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import Teammember from "../Cards/Teammember.vue";
import axiosAuth from "@/shared/configs/axios-auth";
import Document from "../Cards/Document.vue";
import AddDocument from "./AddDocument.vue";
export default {
  name: "DocumentsModal",

  data() {
    return {
      addDucumentModal: false,
      tab: null,
      companyId: "",
      validationState: null,
    };
  },
  components: { Teammember, Document, AddDocument },
  methods: {
    showWarningAlert() {
      this.$swal({
        title: "Möchtest du die Einladung versenden?",
        text: "",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte einladen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$swal.fire(
            "Gesendet!",
            "Die Aufgabe wurde gelöscht.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
    inviteCustomer() {
      if (this.companyId.length < 6) return;
      this.$store.commit("loadingCustomers", true);
      axiosAuth
        .post(
          "/customers",
          { providerId: this.selectedCompany.id, generatedId: this.companyId },
          {
            headers: {
              version: "v1.1",
            },
          }
        )
        .then((res) => {
          this.$store.commit("loadingCustomers", false);
          this.$store.dispatch("getCustomers", this.selectedCompany.id);
          this.closeAddCustomerModal();
          this.$swal.fire(
            "Einladung gesendet!",
            "Der Kunde wurde eingeladen.",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("loadingCustomers", false);
          this.$store.dispatch("getCustomers", this.selectedCompany.id);
          this.$swal.fire(
            "Fehler bei der Anfrage!",
            "Die Einladung konnte nicht gesendet werden, bitte überprüfe die Firmen-ID.",
            "error"
          );
        });
    },
  },
  computed: {},
};
</script>