<template>
  <div>
    <v-card class="card-shadow border-radius-xl pb-4">
      <v-window v-model="tab">
        <v-window-item value="start">
          <v-toolbar elevation="0">
            <v-btn class="bg-light" elevation="0" @click="$emit('close', true)">
              <v-icon size="15" class="">fas fa-times</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              <div class="font-weight-bolder text-xl">Dokument hochladen</div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div style="width: 64px"></div>
          </v-toolbar>
          <div class="mx-4 mt-3">
            <v-row class="pa-0 ma-0 mx-2 mt-5">
              <v-col cols="12"
                ><v-card class="border-radius-lg">
                  <v-row class="justify-center pt-3 pb-5 px-9">
                    <div class="text-center">
                      <v-avatar
                        size="150"
                        class="ma-3 text-uppercase text-dark bg-gradient-warning"
                      >
                        <v-img
                          v-if="previewUrl"
                          :src="previewUrl"
                          v-on:error="onImgError"
                        />
                      </v-avatar>

                      <div
                        class="my-2 text-warning"
                        style="cursor: pointer"
                        @click="launchFilePicker"
                      >
                        Dokument wählen
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, , image/png, .doc, .pdf, .csv, .xls, .xlsx, .doc,.docx,.xml"
                        style="display: none"
                        ref="file"
                        v-uploader
                      />
                      <div v-if="fileInput" class="mx-2">
                        <v-list-item
                          style="height: 60px"
                          @click="(fileInput = null), (previewUrl = '')"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="wrap-text"
                              ><small style="width: 100%; overflow: hidden">{{
                                fileInput.name
                              }}</small></v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-icon>
                            <div>
                              <v-icon class="text-secondary"
                                >fas fa-times</v-icon
                              >
                            </div>
                          </v-list-item-icon>
                        </v-list-item>
                        <!-- <v-row class="pa-0 ma-0">
                          <div>
                            {{ fileInput.name }}
                          </div>
                          <v-col> icon </v-col>
                        </v-row> -->
                      </div>
                      <!-- <v-file-input
                        accept="image/png, image/jpeg, image/jpg, , image/png, .doc, .pdf, .csv, .xls, .xlsx, .doc,.docx,.xml"
                        style="width: 200px"
                        :key="previewUrl"
                        clearable
                        hide-details=""
                        flat
                        solo
                        label="Dokument wählen"
                        v-model="fileInput"
                        @change="onFileChange"
                        @abort="fileInput = null"
                      ></v-file-input> -->
                    </div>
                  </v-row>
                </v-card></v-col
              ></v-row
            >
          </div>
          <v-divider></v-divider>

          <v-list-item style="height: 60px">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Projekt</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span v-if="selectedProject"
                  >{{ `${selectedProject.title || ""}` }}
                </span>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item style="height: 60px">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Kunde</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <div
                  v-if="selectedProject.customer"
                  class="mr-2 pt-1font-weight-normal"
                >
                  {{ selectedProject.customer.name }}
                  {{ selectedProject.customer.legalForm }}
                </div>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item style="height: 60px" @click="tab = 'type'">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bolder text-md"
                >Typ</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-icon>
              <div class="mr-3 pt-1 secondary--text text-md">
                <span>{{ form.type }} </span>
              </div>
              <div>
                <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
              </div>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions class="mx-2">
            <v-btn
              block
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-uppercase text-white btn-default bg-default mt-3"
              small
              @click="uploadDocument"
              ><span v-if="!uploadFile">Hochladen</span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular
            ></v-btn> </v-card-actions
        ></v-window-item>
        <v-window-item value="type"
          ><v-toolbar elevation="0">
            <v-btn class="bg-light" elevation="0" @click="tab = 'start'">
              <v-icon class="text-secondary">fas fa-chevron-left</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-toolbar-title>
              <div class="font-weight-bolder text-xl">Kategorien</div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div style="width: 64px"></div>
          </v-toolbar>
          <v-divider></v-divider>
          <v-list class="pa-0">
            <div v-for="(category, index) in types" :key="category + index">
              <v-list-item @click="selectType(category.title)">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bolder text-md">{{
                    category.title
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <div>
                    <v-icon class="text-secondary">fas fa-chevron-right</v-icon>
                  </div>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>
    
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import Teammember from "../Cards/Teammember.vue";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "AddCustomerModal",
  data() {
    return {
      tab: null,
      companyId: "",
      validationState: null,
      fileInput: null,
      uploadFile: false,
      previewUrl: "",
      form: {
        type: null,
      },
    };
  },
  components: { Teammember },
  directives: {
    uploader: {
      bind(el, binding, vnode) {
        el.addEventListener("change", (e) => {
          vnode.context.onFileChange(e);
          if (e.target.files[0] !== undefined) {
            vnode.context.fileInput = e.target.files[0];
            vnode.context.onFileChange(e);
          }
        });
      },
    },
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    selectType(type) {
      this.form.type = type;
      this.tab = "start";
    },
    async uploadDocument() {
      if (this.fileInput && this.form.type) {
        this.uploadFile = true;
        let formData = new FormData();
        formData.append("file", this.fileInput);
        formData.append("projectId", this.selectedProject.id);
        formData.append("fileType", ".pdf");
        formData.append("category", this.form.type);
        await axiosAuth
          .post("/documents", formData)
          .then((res) => {
            this.fileInput = null;
            this.previewUrl = null;
            this.$store.dispatch("getProjects", this.selectedCompany.id);
            this.$store.dispatch("getSelectedProject", this.selectedProject.id);
            this.$emit("close", true);
            this.uploadFile = false;
          })
          .catch((error) => {
            console.log(error.response);
            this.uploadFile = false;
          });
      }
    },
    onFileChange: function (event) {
      const file = this.fileInput;
      if (!file) {
        return false;
      }
      if (!file.type.match("image.*")) {
        return false;
      }
      const reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        that.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {},
};
</script>