<template>
  <div v-if="document">
    <v-card class="border-radius-md bg-light ma-2 pa-2 ma-4">
      <div>
        <v-row class="align-center">
          <v-col cols="2" @click="trySet()">
            <v-avatar size="50" class="ma-3 text-uppercase text-dark bg-white">
              <div>
                {{ document.category[0] }}
              </div>
            </v-avatar>
          </v-col>
          <v-col cols="8" @click="trySet()">
            <div class="my-1 font-weight-bolder text-md">
              {{ document.category }}
            </div>
            {{ document.fileName }}
          </v-col>

          <v-col cols="2" v-if="selectedType == 'provider'">
            <v-btn
              block
              class="bg-danger text-white"
              style="z-index: 100; max-width: 40px !important"
              elevation="0"
              @click="showWarningAlert()"
            >
              <v-icon size="15" class="">fas fa-trash</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

import axiosAuth from "@/shared/configs/axios-auth";

export default {
  props: {
    document: null,
  },
  methods: {
    trySet() {
      window.open(this.getImage(this.document.filePath), "_blank");
    },
    showWarningAlert() {
      this.$swal({
        title: "Bist du dir sicher, dass du das Dokument löschen möchtest?",
        text: "Du kannst dies nicht rückgängig machen!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Ja, bitte löschen!",
        cancelButtonText: "Nein, abbrechen!",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$store.commit("loadingCustomers", true);
          axiosAuth
            .delete("/documents/" + this.document.id, {
              headers: {
                version: "v1.1",
              },
            })
            .then(async (res) => {
              await this.$store.commit("loadingAppointments", false);
              await this.$store.dispatch(
                "getProjects",
                this.selectedCompany.id
              );
              if (this.selectedCompany)
                await this.$store.dispatch(
                  "getSelectedCompany",
                  this.selectedCompany.id
                );
              if (this.selectedProject)
                await this.$store.dispatch(
                  "getSelectedProject",
                  this.selectedProject.id
                );
              this.$swal.fire(
                "Gelöscht!",
                "Das Dokument wurde gelöscht.",
                "success"
              );
              this.$emit("close", true);
            })
            .catch((error) => {
              console.log(error);

              this.$store.commit("loadingAppointments", false);
              this.$store.dispatch("getProjects", this.selectedCompany.id);
              this.$swal.fire(
                "Fehler bei der Anfrage!",
                "Das Dokument konnte nicht gelöscht werden.",
                "error"
              );
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire("Löschen abgebrochen", "", "error");
        }
      });
    },
  },
};
</script>

<style>
</style>